import {get} from "./backend"

export function getGames(setGames, storeId){
    get(
        "games",
        {store: storeId}
    ).then(response => {setGames(response.data)})
}


export function getExpansions(gameSlug, handleResponse){
    get(
         `games/${gameSlug}/expansions/`,
        {}
    ).then(response => {handleResponse(response.data)})
}